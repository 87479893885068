import React from 'react'
import { Container, MenuLink, Wrapper, Column } from './styles'

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Column>
            <h4>Sitemap</h4>
            <MenuLink to="/">Home</MenuLink>
            <MenuLink to="/store">Mip Maps</MenuLink>
            <MenuLink to="/scribbles">Scribbles</MenuLink>
            <MenuLink to="/contact">About & Contact</MenuLink>
        </Column>

        <Column>
            <h4>External Links</h4>
            <MenuLink to="https://www.sussexlife.co.uk/out-about/places/lucie-conoley-map-illustrator-1-6509099">Sussex Life</MenuLink>
            <MenuLink to="https://www.mirror.co.uk/lifestyle/going-out/artist-lucie-conoleys-paintings-map-1931900">Mirror</MenuLink>
        </Column>

        <Column>
            <h4>About</h4>
            <p>I believe most people have a love of maps. Maps tell us where we are, where we have been and where we dream to go. We engage instantly with the mass of information and make sense of it through finding something we can familiarise ourselves with.</p>
        </Column>
      </Container>
    </Wrapper>
  )
}

export default Footer
