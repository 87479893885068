/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import Layout from '~/layouts'
import { ContactProvider } from './src/provider/ContactContext';

export function wrapRootElement({ element }){
    return <ContactProvider>{element}</ContactProvider>
}