import React from 'react'
import Image from 'gatsby-image'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import Typography from 'typography'

import optisans_woff2 from '../fonts/optisansserif-shaded-webfont.woff2'
import optisans_woff from '../fonts/optisansserif-shaded-webfont.woff'
import westsac_woff2 from '../fonts/westsac-webfont.woff2'
import westsac_woff from '../fonts/westsac-webfont.woff'

export const breakpoints = {
  s: 576,
  m: 768,
  l: 1024,
  xl: 1600,
}

export const colours = {
  lightblue: '#e4fffd',
  oldblue: '#4000e0',
  blue: '#000',
  pale: '#ffffef',
  contrast: '#ff63d8',
  pink: '#DC85B7',
  purple: '#5c498c',
  black: '#000',
  white: '#fff',
}

const typography = new Typography({
  baseFontSize: '24px',
  baseLineHeight: 1.666,
  headerFontFamily: ['westsacregular', 'sans-serif'],
  bodyFontFamily: ['westsacregular', 'sans-serif'],
})

export default typography

export const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      @font-face {
        font-family: 'optisans';
        src: url(${optisans_woff2}) format('woff2'),
          url(${optisans_woff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'westsacregular';
        src: url(${westsac_woff2}) format('woff2'),
          url(${westsac_woff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      body {
        margin: 0;
        background-color: ${colours.white};
        color: ${colours.blue};
      }
      button {
        box-sizing: content-box;
        text-transform: Uppercase;
        padding: 0.8rem 0;
        margin: 0.5rem 0;
        width: 100%;
        background: ${colours.purple};
        border: none;
        color: white;
        border-radius: 30px;
      }
      button:hover {
        background: ${colours.pink};
        transform: rotate(2deg);
        transition: 0.5s;
        color: ${colours.black};
      }
      button:active {
        outline: 4px solid black;
        outline-offset: -4px;
      }
      textarea {
        height: 100px;
      }
      img{
        border-radius: 5px;
      }
      fieldset {
        border: none;
        padding: 0;
      }
      .error {
        background: red;
        padding: 0.5rem;
        color: white;
        font-weight: bold;
        display: inline-block;
      }
      select,
      input,
      textarea {
        box-sizing: border-box;
        display: block;
        width: 100%;
        min-width: 300px;
        max-width: 600px;

        appearance: none;
        padding: 0.5rem 0rem;
        padding-left: 1rem;
        margin-right: -1rem;

        text-overflow: '';
        cursor: pointer;
    
        background-color:  ${colours.white};
        background-size: 24px 24px;
        padding-right: 0;
        border: 1px solid;
        border-right: 4px solid;
        border-bottom: 4px solid;
        border-radius: 5px;
      }
      .rule {
        width: 40px;
        border: none;
      }
      .colour-alt {
        color: #ff4040;
      }
      .background-alt {
        background-color: #ff78eb;
      }
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: 'optisans', sans-serif;
        text-transform: uppercase;
      }
      h2.is-1.title {
        font-size: 2rem;
      }
      
      
    `}
  />
)

export const Img = styled(Image)`
  max-width: 100 %;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
`

export const header3 = styled.h3`
  ${colours.pink};
`
export const StyledSVG = styled.svg`
  position: absolute;
  height: 100%;
  width: 100%;
`

export const HeaderHighlight = styled.div`
  display: flex;
  align-items: center;
  color: black;
  height: 400px;
  border-radius: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  margin: 2rem;
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-position-y: center;
  fill: #efefef;
  h2,
  p,
  h1,
  h3,
  h4,
  h5 {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 2rem;
    font-size: 2rem;
  }
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: ${breakpoints.xl};
`

export const InnerContainer = styled.div`
`

export const TwoColumnGrid = styled.div`
  display: block;
`

export const GridItem = styled.div`
  @media (min-width: ${breakpoints.l}px) {
    width: calc((100% - ((2 - 1) * 2%)) / (2 / 1));
    margin-right: 2%;
    display: inline-block;
    vertical-align: top;
  }
  &:last-child {
    margin-right: 0;
  }
`

export const GridRight = styled.div`
  @media (min-width: ${breakpoints.l}px) {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }
`

export const MainContent = styled.main`
  margin-top: 80px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`

export const Intro = styled.h2`
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-transform: uppercase;
  padding: 1rem;
  font-size: 3rem;
  font-weight: 800;
  max-width: ${breakpoints.xl}px;
`
