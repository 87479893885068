import React, { useContext } from 'react'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'

import StoreContext from '~/context/StoreContext'
import {
  CartCounter,
  Container,
  MenuLink,
  Wrapper,
  StyledSVG,
  SocialContainer,
} from './styles'

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const Navigation = ({ siteTitle }) => {
  const [hasItems, quantity] = useQuantity()

  return (
    <Wrapper>
      <Container>
        <MenuLink to="/">Home</MenuLink>
        <MenuLink to="/store">Mip Maps</MenuLink>
        <MenuLink to="/scribbles">Scribbles</MenuLink>
        {/* <MenuLink to="/cart">
          {hasItems && <CartCounter>{quantity}</CartCounter>}
          Basket
        </MenuLink> */}
        <MenuLink to="/contact">About & Contact</MenuLink>
        <SocialContainer>
          <MenuLink to="https://www.facebook.com/LucieConoleyArt">
            <StyledSVG width="100%" viewBox="0 0 292 292">
              <g>
                <path d="M145.659,0C226.109,0 291.319,65.219 291.319,145.66C291.319,226.11 226.109,291.319 145.659,291.319C65.209,291.319 0,226.109 0,145.66C0,65.219 65.21,0 145.659,0Z" />
                <path
                  d="M163.394,100.277L182.166,100.277L182.166,72.547L160.099,72.547L160.099,72.647C133.361,73.594 127.881,88.624 127.398,104.41L127.343,104.41L127.343,118.257L109.136,118.257L109.136,145.413L127.343,145.413L127.343,218.206L154.782,218.206L154.782,145.413L177.259,145.413L181.601,118.257L154.791,118.257L154.791,109.891C154.791,104.556 158.341,100.277 163.394,100.277Z"
                  fill="white"
                />
              </g>
            </StyledSVG>
          </MenuLink>
          <MenuLink to="https://www.instagram.com/lucieconoley">
            <StyledSVG width="100%" viewBox="0 0 292 292">
              <g>
                <path d="M145.659,0C226.099,0 291.319,65.219 291.319,145.66C291.319,226.101 226.1,291.319 145.66,291.319C65.22,291.319 0,226.1 0,145.66C0,65.22 65.21,0 145.659,0Z" />
                <path
                  d="M195.93,63.708L95.38,63.708C77.91,63.708 63.708,77.919 63.708,95.38L63.708,195.94C63.708,213.41 77.919,227.612 95.38,227.612L195.94,227.612C213.41,227.612 227.612,213.401 227.612,195.94L227.612,95.38C227.611,77.919 213.4,63.708 195.93,63.708ZM205.908,82.034L209.495,82.025L209.495,109.227L182.093,109.318L182.002,82.116L205.908,82.034ZM145.66,118.239C168.392,118.239 173.08,139.578 173.08,145.668C173.08,160.771 160.772,173.079 145.66,173.079C130.539,173.079 118.24,160.771 118.24,145.668C118.23,139.578 122.928,118.239 145.66,118.239ZM209.65,193.955C209.65,202.613 202.613,209.659 193.937,209.659L97.073,209.659C88.406,209.659 81.36,202.622 81.36,193.955L81.36,127.416L104.119,127.416C102.007,132.614 100.814,139.715 100.814,145.669C100.814,170.377 120.915,190.487 145.632,190.487C170.349,190.487 190.44,170.377 190.44,145.669C190.44,139.715 189.247,132.614 187.144,127.416L209.63,127.416L209.63,193.955L209.65,193.955Z"
                  fill="white"
                />
              </g>
            </StyledSVG>
          </MenuLink>
        </SocialContainer>
      </Container>
    </Wrapper>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation
