import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { breakpoints, colours } from '../../utils/styles'

export const Wrapper = styled.div`
  background: transparent;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  font-family: sans-serif;
  @media (min-width: ${breakpoints.m}px){
    max-width: 50%;
  }
  h4{
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 1.45rem;
  margin: 0 auto;
  max-width: ${breakpoints.xl}px;
  flex-wrap: wrap;
  font-size: 0.8rem;
`

export const MenuLink = styled(Link)`
  color: ${colours.purple};
  text-decoration: none;
  white-space: no-wrap;
  &:hover{
    
    color: ${colours.pink};
    
  }
`
