import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { breakpoints, colours } from '../../utils/styles'

export const Wrapper = styled.div`
  background: transparent;
`

export const StyledSVG = styled.svg`

  margin-left: -32px;
  margin-top: -32px;
  position: absolute;
  height: 64px;
  width: 64px;
`

export const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 1.45rem;
  max-width: ${breakpoints.xl}px;
  flex-wrap: wrap;
  a{
    width: 64px;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 1.45rem;
  margin: 0 auto;
  max-width: ${breakpoints.xl}px;
  flex-wrap: wrap;
`

export const MenuLink = styled(Link)`
  color: ${colours.blue};
  text-decoration: none;
  font-size: 2rem;
  font-weight: bold;
  padding: 0.2rem 1rem;
  white-space: no-wrap;
  @media (max-width: ${breakpoints.s}px){
    font-size: 1.4rem
  }
  &:hover{
    
    box-shadow: 0px 0px ${colours.blue};
    
  }
`

export const CartCounter = styled.span`
  background-color: ${colours.oldblue};
  color: white;
  padding: 2px 10px;
  font-size: 1.2rem;
  float: right;
  margin: -20px -35px;
  z-index: 20;
`

    
     
        
          
            
              